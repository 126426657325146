import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Confetti from 'react-confetti';

const initialKindnessActions = [
  { id: 1, text: "Help someone with their homework", color: "#FFE4E1" },
  { id: 2, text: "Give a compliment", color: "#E0FFFF" },
  { id: 3, text: "Include someone in your game", color: "#FFE4B5" },
  { id: 4, text: "Help clean up the classroom", color: "#E0FFF0" },
  { id: 5, text: "Stand up for someone being teased", color: "#FFE4E1" },
  { id: 6, text: "Welcome a new student", color: "#E0FFFF" },
  { id: 7, text: "Share your school supplies", color: "#FFE4B5" },
  { id: 8, text: "Help carry someone's books", color: "#E0FFF0" },
  { id: 9, text: "Listen when someone is sad", color: "#FFE4E1" }
];

const BuddyActivity3 = () => {
  const navigate = useNavigate();
  const [kindnessActions, setKindnessActions] = useState(initialKindnessActions);
  const [customAction, setCustomAction] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [leaves, setLeaves] = useState([]);
  const [showCompletion, setShowCompletion] = useState(false);
  const treeAreaRef = useRef(null);

  const handleDragStart = (e, action) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(action));
    e.target.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const action = JSON.parse(e.dataTransfer.getData('text/plain'));
    const rect = treeAreaRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    const newLeaf = {
      ...action,
      x: Math.max(10, Math.min(90, x)), // keep within the 10%-90% range
      y: Math.max(10, Math.min(90, y)),
      rotation: Math.random() * 360
    };

    setLeaves(prev => [...prev, newLeaf]);
    setKindnessActions(prev => prev.filter(a => a.id !== action.id));

    // Check if this was the last leaf
    if (kindnessActions.length === 1) {
      setTimeout(() => {
        setShowCompletion(true);
      }, 1000);
    }
  };

  const handleCustomActionSubmit = () => {
    if (customAction.trim()) {
      const newAction = {
        id: Date.now(),
        text: customAction,
        color:
          initialKindnessActions[
            Math.floor(Math.random() * initialKindnessActions.length)
          ].color
      };
      setKindnessActions(prev => [...prev, newAction]);
      setCustomAction('');
      setShowCustomInput(false);
    }
  };

  /**
   * Reveal more branches as leaves accumulate
   */
  const getTreeStage = () => {
    const progress = leaves.length;
    if (progress >= 8) return 4;
    if (progress >= 6) return 3;
    if (progress >= 3) return 2;
    if (progress >= 1) return 1;
    return 0;
  };

  return (
    <div className="flex flex-col min-h-screen bg-amber-300">
      {showCompletion && <Confetti />}
      <Header />
      <NavBar />

      <main className="flex-1 relative px-4 py-8">
        <button
          onClick={() => navigate('/buddy-island')}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-amber-700 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-amber-500 shadow-md"
        >
          ← Back to Buddy Island
        </button>

        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-8">
            <h1 className="text-5xl font-bold text-white mb-4">
              Kindness Tree 🌳
            </h1>
            <p className="text-2xl text-white mb-4">
              Watch your kindness grow and bloom!
            </p>
            {showInstructions && (
              <div className="bg-white/90 p-4 rounded-xl max-w-2xl mx-auto mb-8">
                <p className="text-lg text-amber-700 mb-2">
                  1. Drag kind actions from the left panel onto the tree area
                </p>
                <p className="text-lg text-amber-700 mb-2">
                  2. Watch your tree grow with each kind action
                </p>
                <p className="text-lg text-amber-700 mb-4">
                  3. Add your own kind actions using the "+" button
                </p>
                <button
                  onClick={() => setShowInstructions(false)}
                  className="text-amber-600 hover:text-amber-700 font-bold"
                >
                  Got it! Let's start! 👍
                </button>
              </div>
            )}
          </div>

          <div className="flex gap-8">
            {/* Kindness Actions Panel */}
            <div className="w-1/3">
              <div className="bg-white rounded-3xl p-6 shadow-xl">
                <h2 className="text-2xl font-bold text-amber-600 mb-4">
                  Kindness Actions
                </h2>
                <div className="space-y-3">
                  {kindnessActions.map(action => (
                    <div
                      key={action.id}
                      draggable
                      onDragStart={(e) => handleDragStart(e, action)}
                      onDragEnd={handleDragEnd}
                      className="p-4 rounded-xl cursor-move transition-all duration-300 hover:scale-105"
                      style={{ backgroundColor: action.color }}
                    >
                      {action.text}
                    </div>
                  ))}
                  {!showCustomInput ? (
                    <button
                      onClick={() => setShowCustomInput(true)}
                      className="w-full p-4 rounded-xl bg-amber-100 hover:bg-amber-200 transition-colors text-amber-700 font-semibold"
                    >
                      + Add Your Own Kind Action
                    </button>
                  ) : (
                    <div className="space-y-2">
                      <input
                        type="text"
                        value={customAction}
                        onChange={(e) => setCustomAction(e.target.value)}
                        placeholder="Type your kind action..."
                        className="w-full p-4 rounded-xl border-2 border-amber-300 focus:border-amber-500"
                      />
                      <div className="flex gap-2">
                        <button
                          onClick={handleCustomActionSubmit}
                          className="flex-1 p-2 rounded-xl bg-amber-500 text-white hover:bg-amber-600"
                        >
                          Add
                        </button>
                        <button
                          onClick={() => {
                            setShowCustomInput(false);
                            setCustomAction('');
                          }}
                          className="flex-1 p-2 rounded-xl bg-gray-300 hover:bg-gray-400"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Tree Area */}
            <div
              ref={treeAreaRef}
              className="flex-1 bg-white rounded-3xl p-6 shadow-xl relative min-h-[600px] overflow-hidden"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <svg
                viewBox="0 0 100 100"
                className="w-full h-full absolute inset-0"
                style={{ minHeight: '500px' }}
              >
                {/* TRUNK: from bottom (y=100) up to ~60 */}
                <path
                  d="M48,100 L48,60 C48,55 52,55 52,60 L52,100 Z"
                  fill="#8B4513"
                />

                {/* --- STAGE 1 --- Large outward branches --- */}
                {getTreeStage() >= 1 && (
                  <>
                    <path
                      d="M50,60 C35,45 15,40 15,25"
                      stroke="#8B4513"
                      strokeWidth="3"
                      strokeLinecap="round"
                      fill="none"
                    />
                    <path
                      d="M50,60 C65,45 85,40 85,25"
                      stroke="#8B4513"
                      strokeWidth="3"
                      strokeLinecap="round"
                      fill="none"
                    />
                  </>
                )}

                {/* --- STAGE 2 --- Additional outward branches --- */}
                {getTreeStage() >= 2 && (
                  <>
                    <path
                      d="M50,60 C40,50 25,45 25,35"
                      stroke="#8B4513"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      fill="none"
                    />
                    <path
                      d="M50,60 C60,50 75,45 75,35"
                      stroke="#8B4513"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      fill="none"
                    />
                  </>
                )}

                {/* --- STAGE 3 --- Two top branches flaring outward --- */}
                {getTreeStage() >= 3 && (
                  <>
                    {/* Left top branch from near left side out to top-left */}
                    <path
                      d="M25,35 C15,25 10,20 10,10"
                      stroke="#8B4513"
                      strokeWidth="2"
                      strokeLinecap="round"
                      fill="none"
                    />
                    {/* Right top branch from near right side out to top-right */}
                    <path
                      d="M75,35 C85,25 90,20 90,10"
                      stroke="#8B4513"
                      strokeWidth="2"
                      strokeLinecap="round"
                      fill="none"
                    />
                  </>
                )}

                {/* --- STAGE 4 --- Final small branches or flourish further outward --- */}
                {getTreeStage() >= 4 && (
                  <>
                    {/* Far-left mini branch from (10,10) to upper left */}
                    <path
                      d="M10,10 C5,5 15,2 20,5"
                      stroke="#8B4513"
                      strokeWidth="2"
                      strokeLinecap="round"
                      fill="none"
                    />
                    {/* Far-right mini branch from (90,10) to upper right */}
                    <path
                      d="M90,10 C95,5 85,2 80,5"
                      stroke="#8B4513"
                      strokeWidth="2"
                      strokeLinecap="round"
                      fill="none"
                    />
                  </>
                )}

                {/* Leaves placed by user drops */}
                {leaves.map((leaf, index) => (
                  <g
                    key={index}
                    transform={`translate(${leaf.x}, ${leaf.y}) rotate(${leaf.rotation})`}
                    className="transition-all duration-500"
                  >
                    <path
                      d="M-6,-3 Q0,-8 6,-3 Q0,2 -6,-3"
                      fill={leaf.color}
                      className="animate-sway"
                    >
                      <title>{leaf.text}</title>
                    </path>
                  </g>
                ))}
              </svg>

              {showCompletion && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/90 rounded-3xl">
                  <div className="text-center p-8">
                    <h2 className="text-4xl font-bold text-amber-600 mb-4">
                      🌳 Your Kindness Tree is Blooming! 🌸
                    </h2>
                    <p className="text-xl text-amber-700 mb-6">
                      You've created a beautiful tree full of kindness! Keep spreading these acts of kindness in your daily life!
                    </p>
                    <button
                      onClick={() => navigate('/buddy-island')}
                      className="bg-amber-500 text-white px-6 py-3 rounded-full hover:bg-amber-600 transition-all duration-300"
                    >
                      Return to Buddy Island →
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />

      <style jsx>{`
        @keyframes sway {
          0%, 100% {
            transform: rotate(-5deg);
          }
          50% {
            transform: rotate(5deg);
          }
        }
        .animate-sway {
          animation: sway 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default BuddyActivity3;