import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [unlockedModules, setUnlockedModules] = useState({
    'emotions-falls': false,
    'together-land': false,
    'lava-island': false,
    'buddy-island': false
  });

  const login = (password) => {
    switch (password) {
      case "buddy1":
        setUnlockedModules(prev => ({
          ...prev,
          'emotions-falls': true
        }));
        return true;
      case "buddy2":
        setUnlockedModules(prev => ({
          ...prev,
          'lava-island': true
        }));
        return true;
      case "heart1":
        setUnlockedModules(prev => ({
          ...prev,
          'together-land': true
        }));
        return true;
      case "heart2":
        setUnlockedModules(prev => ({
          ...prev,
          'buddy-island': true
        }));
        return true;
      default:
        return false;
    }
  };

  const logout = () => {
    setUnlockedModules({
      'emotions-falls': false,
      'together-land': false,
      'lava-island': false,
      'buddy-island': false
    });
  };

  return (
    <AuthContext.Provider value={{ unlockedModules, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};