import React, { useState, useRef } from 'react';
import { Heart, Star, Sun, Cloud, CheckCircle2, ChevronRight, ChevronLeft, Home } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';

const TogetherActivity1 = () => {
  const navigate = useNavigate();
  const [ripples, setRipples] = useState([]);
  const [selectedEmotion, setSelectedEmotion] = useState('happy');
  const [currentStep, setCurrentStep] = useState(0);
  const [reflections, setReflections] = useState({});
  const [showCongrats, setShowCongrats] = useState(false);
  const containerRef = useRef(null);

  const emotions = {
    happy: { 
      color: '#FFD700', 
      icon: Sun, 
      label: 'Joy',
      emoji: '😊',
      question: "When you're happy, how do your smiles help others feel good too?",
      scenario: "You got a gold star on your homework and you're super excited!" 
    },
    love: { 
      color: '#FF69B4', 
      icon: Heart, 
      label: 'Love',
      emoji: '💖',
      question: "How does being kind make your friends feel special?",
      scenario: "You shared your favorite snack with a friend who forgot their lunch." 
    },
    calm: { 
      color: '#87CEEB', 
      icon: Cloud, 
      label: 'Calm',
      emoji: '😌',
      question: "How does staying peaceful help your classmates feel relaxed?",
      scenario: "Everyone's nervous about the big test, but you're staying cool!" 
    },
    proud: { 
      color: '#9370DB', 
      icon: Star, 
      label: 'Pride',
      emoji: '🌟',
      question: "How can celebrating others make the whole class shine?",
      scenario: "Your friend just learned to ride a bike, and you're cheering them on!" 
    }
  };

  const steps = [
    { title: "Pick a Feeling", description: "What emotion do you want to explore?" },
    { title: "Story Time", description: "Imagine this happening to you" },
    { title: "Spread the Feeling", description: "Share your emotion with others" },
    { title: "Think About It", description: "How do your feelings affect friends?" }
  ];

  const handleBackToMain = () => {
    navigate('/together-land');
  };

  const createRipple = (e) => {
    if (!containerRef.current || currentStep !== 2) return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const newRipple = {
      id: Date.now(),
      x,
      y,
      emotion: selectedEmotion,
    };

    setRipples(prev => [...prev, newRipple]);

    setTimeout(() => {
      setRipples(prev => prev.filter(ripple => ripple.id !== newRipple.id));
    }, 2000);
  };

  const handleReflectionSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const reflection = formData.get('reflection');
    
    setReflections(prev => ({
      ...prev,
      [selectedEmotion]: reflection
    }));

    if (Object.keys(reflections).length >= 3) {
      setShowCongrats(true);
    }
    
    setCurrentStep(0);
  };

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-green-500 to-emerald-500">
      <Header />
      <NavBar />
      
      <main className="flex-1 relative px-4 py-8">
        {/* Back to Module Button */}
        <button
          onClick={handleBackToMain}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-green-700 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-green-500 shadow-md flex items-center gap-2"
        >
          <Home className="w-5 h-5" /> Back to Together Land
        </button>

        <div className="max-w-6xl mx-auto space-y-8 mt-16">
          {/* Progress Steps */}
          <div className="flex justify-between mb-8 px-4">
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-center w-1/4">
                <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
                  index === currentStep ? 'bg-white text-green-500 scale-110 transform transition-all duration-300' :
                  index < currentStep ? 'bg-green-300 text-white' :
                  'bg-white/30 text-white'
                } shadow-lg`}>
                  {index < currentStep ? <CheckCircle2 className="w-8 h-8" /> : index + 1}
                </div>
                <div className="text-center mt-2">
                  <p className="text-white font-bold">{step.title}</p>
                  <p className="text-green-100 text-sm">{step.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Main Content Area */}
          <div className="bg-white/10 backdrop-blur-md border border-white/20 rounded-xl p-8 shadow-lg">
            {currentStep === 0 && (
              <div className="space-y-6">
                <h2 className="text-3xl font-bold text-white text-center mb-6">
                  Choose Your Emotion! {emotions[selectedEmotion].emoji}
                </h2>
                <div className="flex justify-center gap-6">
                  {Object.entries(emotions).map(([key, { color, icon: Icon, label, emoji }]) => (
                    <button
                      key={key}
                      onClick={() => {
                        setSelectedEmotion(key);
                        handleNextStep();
                      }}
                      className={`p-6 rounded-xl transition-all duration-300 hover:scale-110 
                        ${selectedEmotion === key ? 'ring-4 ring-white scale-110' : ''}
                        transform hover:-translate-y-2`}
                      style={{ backgroundColor: color }}
                    >
                      <Icon className="w-12 h-12 text-white mb-2" />
                      <span className="block text-lg text-white font-bold">{label}</span>
                      <span className="text-2xl">{emoji}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}

            {currentStep === 1 && (
              <div className="text-center space-y-6">
                <h2 className="text-3xl font-bold text-white">Imagine This! {emotions[selectedEmotion].emoji}</h2>
                <div className="bg-white/10 p-8 rounded-xl border border-white/20">
                  <p className="text-2xl text-green-100">{emotions[selectedEmotion].scenario}</p>
                </div>
                <p className="text-lg text-green-100">How would this make you feel?</p>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <div 
                  ref={containerRef}
                  onClick={createRipple}
                  className="relative h-96 bg-white/5 backdrop-blur-sm rounded-3xl overflow-hidden cursor-pointer border border-white/10 shadow-xl"
                >
                  {ripples.map(ripple => {
                    const Icon = emotions[ripple.emotion].icon;
                    return (
                      <div
                        key={ripple.id}
                        className="absolute pointer-events-none"
                        style={{
                          left: ripple.x - 10,
                          top: ripple.y - 10,
                        }}
                      >
                        <Icon 
                          className="w-5 h-5 absolute"
                          style={{ color: emotions[ripple.emotion].color }}
                        />
                        {[1, 2, 3].map((ring) => (
                          <div
                            key={ring}
                            className="absolute rounded-full animate-ripple"
                            style={{
                              width: '20px',
                              height: '20px',
                              border: `2px solid ${emotions[ripple.emotion].color}`,
                              animation: `ripple ${ring * 0.7}s linear`,
                              opacity: 0,
                            }}
                          />
                        ))}
                      </div>
                    );
                  })}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <p className="text-green-100 text-2xl">
                      Click anywhere to spread your {emotions[selectedEmotion].label}! {emotions[selectedEmotion].emoji}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <form onSubmit={handleReflectionSubmit} className="space-y-6">
                <h2 className="text-3xl font-bold text-white text-center">
                  Time to Think! {emotions[selectedEmotion].emoji}
                </h2>
                <div className="bg-white/10 p-6 rounded-xl border border-white/20">
                  <p className="text-xl text-green-100 text-center mb-4">
                    {emotions[selectedEmotion].question}
                  </p>
                </div>
                <textarea
                  name="reflection"
                  required
                  className="w-full h-32 p-4 rounded-xl bg-white/5 border border-white/20 text-white placeholder-green-100 text-lg"
                  placeholder="Write your thoughts here..."
                ></textarea>
                <button 
                  type="submit"
                  className="bg-white/20 hover:bg-white/30 text-white px-8 py-4 rounded-full flex items-center gap-2 mx-auto text-lg font-bold transition-all duration-300 hover:scale-105"
                >
                  Share Your Thoughts! ✨
                </button>
              </form>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-8">
              {currentStep > 0 && (
                <button 
                  onClick={handlePrevStep}
                  className="bg-white/20 hover:bg-white/30 text-white px-6 py-3 rounded-full flex items-center gap-2 transition-all duration-300 hover:scale-105"
                >
                  <ChevronLeft className="w-5 h-5" /> Go Back
                </button>
              )}
              {currentStep < 3 && (
                <button 
                  onClick={handleNextStep}
                  className="bg-white/20 hover:bg-white/30 text-white px-6 py-3 rounded-full flex items-center gap-2 ml-auto transition-all duration-300 hover:scale-105"
                >
                  Next Step <ChevronRight className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>

          {/* Progress Display */}
          <div className="p-6 bg-white/10 backdrop-blur-md rounded-xl border border-white/20">
            <h3 className="text-white font-bold mb-4 text-xl">Your Emotion Journey:</h3>
            <div className="flex gap-4">
              {Object.entries(reflections).map(([emotion, reflection]) => {
                const Icon = emotions[emotion].icon;
                return (
                  <div key={emotion} className="flex items-center gap-2 bg-white/10 p-3 rounded-full">
                    <Icon className="w-6 h-6 text-white" />
                    <span className="text-green-100 text-lg">✓</span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Congratulations Modal */}
          {showCongrats && (
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
              <div className="bg-white/10 backdrop-blur-md p-8 rounded-xl border border-white/20 text-center max-w-lg mx-4">
                <h2 className="text-4xl font-bold text-white mb-4">
                  Amazing Job! 🎉 ⭐️ 🌈
                </h2>
                <p className="text-green-100 text-xl mb-6">
                  Wow! You've learned so much about how your feelings can help others feel good too!
                </p>
                <div className="flex gap-4 justify-center">
                  <button
                    onClick={() => setShowCongrats(false)}
                    className="bg-white/20 hover:bg-white/30 text-white px-6 py-3 rounded-full transition-all duration-300 hover:scale-105"
                  >
                    Keep Exploring! ✨
                  </button>
                  <button
                    onClick={handleBackToMain}
                    className="bg-white/20 hover:bg-white/30 text-white px-6 py-3 rounded-full transition-all duration-300 hover:scale-105"
                  >
                    Back to Together Land 🏠
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <Footer />

      <style jsx global>{`
        @keyframes ripple {
          0% {
            transform: scale(1);
            opacity: 0.8;
          }
          100% {
            transform: scale(15);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default TogetherActivity1;