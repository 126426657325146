import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
// import VideoModal from '../components/VideoModal';

const TogetherLand = () => {
  const navigate = useNavigate();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const backgroundAudioRef = useRef(null);

  useEffect(() => {
    // Audio initialization
    // backgroundAudioRef.current = new Audio('/audio/togetherland/background-voice.m4a');

    // Play the audio
    // backgroundAudioRef.current.play().catch((error) => {
    //   console.error("Audio playback failed:", error);
    // });

    // Cleanup function to pause the audio when component unmounts
    return () => {
      // if (backgroundAudioRef.current) {
      //   backgroundAudioRef.current.pause();
      //   backgroundAudioRef.current.currentTime = 0;
      // }
    };
  }, []);

  const modules = [
    {
      // image: '/images/togetherland/time-management.gif',
      title: 'READ ALOUD:',
      description: 'Time Management for Kids',
      due: 'January Activity 1',
      // videoId: 'VUmAGFHcxZU'
      path: '/together-land/together-activity0' // Added path instead of videoId
    },
    {
      // image: '/images/togetherland/organization.gif',
      title: 'EMOTION ECHOES:',
      description: "Create and observe how emotions affect others around you.",
      due: 'January Activity 2',
      path: '/together-land/together-activity1'
    },
    {
      // image: '/images/togetherland/mindfulness.gif',
      title: 'FEELINGS FLOW:',
      description: 'Combining different emotions and actions to create positive chain reactions.',
      due: 'January Activity 3',
      path: '/together-land/together-activity2'
    },
    {
      // image: '/images/togetherland/calmdown.gif',
      title: 'UNITY BLOCKS:',
      description: 'Collaborative pattern-making game showing strength in differences.',
      due: 'January Activity 4',
      path: '/together-land/together-activity3'
    },
  ];

  const handleNavigate = (path) => {
    navigate(path);
  };

  // const handleVideoClick = (videoId) => {
  //   // Pause audio when modal opens
  //   // if (backgroundAudioRef.current) {
  //   //   backgroundAudioRef.current.pause();
  //   // }
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  //   // Resume audio when modal closes
  //   // if (backgroundAudioRef.current) {
  //   //   backgroundAudioRef.current.play().catch((error) => {
  //   //     console.error("Audio playback failed:", error);
  //   //   });
  //   // }
  // };

  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-b from-green-500 to-emerald-500">
      <Header />
      <NavBar />
      <main
        className="flex-1 relative overflow-hidden w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/togetherland/background.png')" }}
      >
        {/* Leaf (Right) and Cliff (Left) Images */}
        <div className="absolute top-20 left-8 z-10 w-72 md:w-80 lg:w-96 animate-float-slow">
          <img
            src="/images/togetherland/cliff.png"
            alt="Together Land"
            className="w-full h-auto"
          />
        </div>
        <div className="absolute top-28 right-8 z-20 w-64 h-64 md:w-72 md:h-72 animate-bounce-slow">
          <img
            src="/images/onlineclasses/leaf.gif"
            alt="Leaf Character"
            className="w-full h-full"
          />
        </div>

        {/* Content Overlay */}
        <div className="relative pt-48 pb-16 flex flex-col items-center px-4">
          <div className="bg-white/10 backdrop-blur-md p-8 rounded-3xl max-w-4xl w-full text-center shadow-xl border border-white/20">
            <h1 className="text-5xl md:text-6xl font-bold text-white tracking-wider mb-6 animate-fade-in">
              WELCOME TO<br />TOGETHER LAND
            </h1>
            <h2 className="text-2xl md:text-4xl text-white font-bold mb-4">
              SOCIAL AWARENESS
            </h2>
            <p className="text-lg md:text-2xl text-green-100 mb-8">Module 3 | January</p>

            <div className="space-y-6 bg-black/20 p-6 rounded-2xl">
              <p className="text-green-50 text-lg leading-relaxed">
                Goal: We'll explore fun ways to be a helper, a good friend, and a great teammate.
              </p>
              <p className="text-green-50 text-lg leading-relaxed">
                Let's think about how we can use the things we've learned so far like being kind, listening, and working together to make our school a happy and safe place for everyone.
              </p>
            </div>
          </div>

          {/* Modules Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 mt-16 max-w-7xl mx-auto">
            {modules.map((module, index) => (
              <div 
                key={index} 
                className="bg-white/10 backdrop-blur-sm p-8 rounded-3xl flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300 border border-white/20"
              >
                <h3 className="text-white text-lg md:text-xl font-semibold uppercase mb-3">
                  {module.title}
                </h3>
                <p className="text-green-100 text-md md:text-lg mb-2">
                  {module.description}
                </p>
                <p className="text-green-200 text-md md:text-lg mb-4">
                  {module.due}
                </p>
                <button 
                  onClick={() => handleNavigate(module.path)}
                  className="bg-gradient-to-r from-green-400 to-emerald-400 text-white px-10 py-3 rounded-full text-md md:text-lg hover:from-green-500 hover:to-emerald-500 transition-all duration-300 transform hover:-translate-y-1 shadow-lg hover:shadow-xl font-bold"
                >
                  Begin Adventure
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />

      {/* Video Modal */}
      {/* <VideoModal 
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        videoId={modules[0].videoId}
      /> */}

      <style jsx>{`
        @keyframes float-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-20px); }
        }
        
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-15px); }
        }

        @keyframes fade-in {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-float-slow {
          animation: float-slow 6s ease-in-out infinite;
        }

        .animate-bounce-slow {
          animation: bounce-slow 4s ease-in-out infinite;
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default TogetherLand;