import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const ImageSlideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const images = [
    "/images/about/image1.jpg",
    "/images/about/image2.jpg",
    "/images/about/image3.jpg",
    "/images/about/image4.jpg",
    "/images/about/image5.jpg",
    "/images/about/image6.jpg",
    "/images/about/image7.jpg",
    "/images/about/image8.jpg",
    "/images/about/image9.jpg",
    "/images/about/image10.jpg",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative h-full min-h-[400px] bg-gray-100 rounded-2xl overflow-hidden shadow-lg">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full transition-colors ${
              index === currentIndex ? 'bg-white' : 'bg-white/50'
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

const About = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-teal-600 text-white text-center py-16">
          <h2 className="text-4xl font-bold mb-6 tracking-wide">
            "Launching our initiative, we inspire youth leaders, one heart at a time."
          </h2>
        </div>

        {/* About Section */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12">
              <h3 className="text-3xl font-bold text-teal-600 mb-8 tracking-wide text-center md:text-left">
                About
              </h3>
              <div className="flex flex-col md:flex-row gap-12">
                {/* Text Content */}
                <div className="md:w-1/2">
                  <p className="text-gray-600 leading-relaxed text-lg">
                    The Youth SEL Empower Initiative began as a collaboration between the Office of Safety and Youth Development, District 2, and HSFI Leadership. 
                    The initial pilot connected three school districts across New York City to focus on youth-driven SEL leadership. 
                    Designed to elevate leadership within elementary and middle schools, the initiative engages students in SEL Academic Integration and Youth Empowerment, guided by high school mentors from across the city. 
                    This initiative fosters social-emotional learning through climate and culture events, cultivating a sense of belonging and shared experiences among peers.
                  </p>
                  <p className="text-gray-600 leading-relaxed text-lg mt-4">
                    By bridging connections between elementary and higher-level education, the program is driven by the passion and leadership of high school students. 
                    This year, the pilot has expanded to 12 schools, involving over 200 mentors. Additionally, the Youth SEL Committee has grown and bridges central, district and school-based staff to collaboratively equip young people with SEL skills that support deeper learning, stronger relationships, and thriving academic and personal growth. 
                    University Heights High School students, as part of their Education Pathways program, will lead monthly student-led convenings, further embedding youth leadership at the heart of this transformative initiative.
                  </p>
                </div>
                {/* Slideshow */}
                <div className="md:w-1/2">
                  <ImageSlideshow />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;