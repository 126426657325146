import React from 'react';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const EmotionsFalls = () => {
  const modules = [
    {
      // image: '/images/emotionfalls/whatissel.gif',
      title: 'INTRODUCTION TO SEL:',
      description: "What it is and why it's important.",
      due: 'Due November 10th',
      link: 'https://selempower2024.s3.us-east-2.amazonaws.com/final+Month+1.1+xSEL+EMPOWER/index.html',
    },
    {
      // image: '/images/emotionfalls/emotionwheels.gif',
      title: 'EMOTIONS WHEEL:',
      description: 'Identifying and discussing different emotions.',
      due: 'Due November 17th',
      link: 'https://selempower2024.s3.us-east-2.amazonaws.com/Final+Month+1.2+SEL+EMPOWER/index.html',
    },
    {
      // image: '/images/emotionfalls/mindful.gif',
      title: 'MINDFULNESS EXERCISES:',
      description: 'Deep breathing and simple meditation.',
      due: 'Due November 24th',
      link: 'https://selempower2024.s3.us-east-2.amazonaws.com/Month+1.3+SEL+EMPOWER/index.html',
    },
    {
      // image: '/images/emotionfalls/aboutme.gif',
      title: 'THE "ALL ABOUT ME" PROJECT:',
      description: 'Students will create a digital design.',
      due: 'Due December 1st',
      link: 'https://docs.google.com/presentation/d/13FJ20TiwCDcnR11qi-IgttHADq8UKS3KDTh1ESSq97s/pub?start=false&loop=false&delayms=3000&slide=id.p',
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-400 to-teal-500">
      <Header />
      <NavBar />
      <main className="flex-1 relative overflow-hidden">
        {/* Wave Background */}
        <div className="absolute inset-0">
          <img
            src="/images/emotionfalls/bg.jpg"
            alt="Wave Background"
            className="w-full h-full object-cover opacity-50"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/50 to-teal-500/50" />
        </div>

        {/* Waterfall (Cliff) Image */}
        <div className="absolute top-20 left-8 z-10 w-72 md:w-80 lg:w-96 animate-float-slow">
          <img
            src="/images/emotionfalls/waterfall.jpg"
            alt="Waterfall Landscape"
            className="w-full h-auto rounded-3xl shadow-2xl"
          />
        </div>

        {/* Droplet Character */}
        <div className="absolute top-28 right-8 z-20 w-64 h-64 md:w-72 md:h-72 animate-bounce-slow">
          <img
            src="/images/onlineclasses/water.gif"
            alt="Water Drop"
            className="w-full h-full"
          />
        </div>

        {/* Content */}
        <div className="relative pt-48 pb-16 text-center">
          <div className="bg-white/10 backdrop-blur-sm p-8 rounded-3xl max-w-4xl mx-auto mb-16">
            <h1 className="text-5xl md:text-6xl font-bold text-white tracking-wider mb-6 animate-fade-in">
              WELCOME TO<br />EMOTIONS FALLS
            </h1>
            <h2 className="text-2xl md:text-4xl text-white font-bold mb-4">
              THINKING ABOUT YOUR EMOTIONS
            </h2>
            <p className="text-lg md:text-2xl text-blue-100 mb-8">Module 1 | November</p>

            <div className="space-y-6 bg-white/10 p-6 rounded-2xl">
              <p className="text-blue-50 text-lg">
                Goal: Students will understand what SEL is and begin to recognize and understand their own emotions.
              </p>
              <p className="text-blue-50 text-lg leading-relaxed">
                At the end of the lesson, make sure to download your certificate. You'll find the download button at the end of the
                training. Once you have your certificate, please upload it to the "Classwork" section in Google Classroom.
                Completing these lessons is important, and it will help you become a SEL EMPOWER Leader by the end of the
                school year. We're excited to see you learn and grow!
              </p>
            </div>
          </div>

          {/* Modules Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 max-w-7xl mx-auto">
            {modules.map((module, index) => (
              <div 
                key={index} 
                className="bg-white/10 backdrop-blur-sm p-6 rounded-3xl flex flex-col items-center transform hover:scale-105 transition-all duration-300 border border-white/20"
              >
                {/* <img
                  src={module.image}
                  alt={module.title}
                  className="w-64 h-64 mb-6 rounded-2xl"
                /> */}
                <h3 className="text-white text-lg md:text-xl font-semibold text-center uppercase mb-3">
                  {module.title}
                </h3>
                <p className="text-blue-100 text-md md:text-lg text-center mb-2">
                  {module.description}
                </p>
                <p className="text-blue-200 text-md md:text-lg text-center mb-4">
                  {module.due}
                </p>
                <a
                  href={module.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gradient-to-r from-blue-400 to-teal-400 text-white px-10 py-3 rounded-full text-md md:text-lg hover:from-blue-500 hover:to-teal-500 transition-all duration-300 transform hover:-translate-y-1 shadow-lg hover:shadow-xl font-bold"
                >
                  Begin Journey
                </a>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />

      <style jsx>{`
        @keyframes float-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-20px); }
        }
        
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-15px); }
        }

        @keyframes fade-in {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-float-slow {
          animation: float-slow 6s ease-in-out infinite;
        }

        .animate-bounce-slow {
          animation: bounce-slow 4s ease-in-out infinite;
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default EmotionsFalls;