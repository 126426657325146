import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Confetti from 'react-confetti';

const culturalCelebrations = [
  {
    id: 1,
    title: "Lunar New Year",
    description: "A festival celebrating the beginning of a new year on the lunar calendar.",
    traditions: [
      "Red envelopes with money for good luck",
      "Lion and dragon dances",
      "Family reunion dinners",
      "Lantern festivals"
    ],
    learning: "This celebration teaches us about family, renewal, and hope for the future.",
    reflection: "How do you celebrate new beginnings in your family?"
  },
  {
    id: 2,
    title: "Diwali",
    description: "The festival of lights celebrating the triumph of light over darkness.",
    traditions: [
      "Lighting diyas (small oil lamps)",
      "Creating colorful rangoli designs",
      "Sharing sweets with family and friends",
      "Wearing new clothes"
    ],
    learning: "This festival shows us how light and goodness can overcome darkness and difficulties.",
    reflection: "What brings light and joy to your life?"
  },
  {
    id: 3,
    title: "Eid al-Fitr",
    description: "A celebration marking the end of Ramadan, a month of fasting.",
    traditions: [
      "Sharing special meals with family",
      "Giving gifts and charity",
      "Wearing new clothes",
      "Community prayers and gatherings"
    ],
    learning: "This celebration teaches us about gratitude, generosity, and community.",
    reflection: "How do you show gratitude to others?"
  },
  {
    id: 4,
    title: "Day of the Dead",
    description: "A celebration honoring and remembering loved ones who have passed away.",
    traditions: [
      "Creating colorful altars with photos",
      "Sharing favorite foods of loved ones",
      "Face painting and parades",
      "Storytelling about family history"
    ],
    learning: "This celebration helps us understand the importance of remembering and honoring our family history.",
    reflection: "What special memories do you have of your family?"
  },
  {
    id: 5,
    title: "Indigenous Peoples' Day",
    description: "A day celebrating Native American cultures and traditions.",
    traditions: [
      "Traditional dances and music",
      "Storytelling circles",
      "Art and craft demonstrations",
      "Community feasts"
    ],
    learning: "This day helps us appreciate the rich history and traditions of Native peoples.",
    reflection: "What can we learn from different cultural traditions?"
  },
  {
    id: 6,
    title: "Hanukkah",
    description: "The Festival of Lights celebrating faith and miracles.",
    traditions: [
      "Lighting the menorah",
      "Playing dreidel games",
      "Eating special foods like latkes",
      "Exchanging gifts"
    ],
    learning: "This festival teaches us about persistence and keeping traditions alive.",
    reflection: "What traditions are important in your life?"
  }
];

const BuddyActivity2 = () => {
  const navigate = useNavigate();
  const [currentCelebration, setCurrentCelebration] = useState(0);
  const [showTraditions, setShowTraditions] = useState(false);
  const [showLearning, setShowLearning] = useState(false);
  const [selectedTraditions, setSelectedTraditions] = useState([]);
  const [completedCelebrations, setCompletedCelebrations] = useState(new Set());
  const [showCompletion, setShowCompletion] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [response, setResponse] = useState('');

  const handleNext = () => {
    if (response.length > 0) {
      setCompletedCelebrations(prev => new Set([...prev, currentCelebration]));
    }
    
    if (currentCelebration < culturalCelebrations.length - 1) {
      setCurrentCelebration(prev => prev + 1);
      setShowTraditions(false);
      setShowLearning(false);
      setSelectedTraditions([]);
      setResponse('');
    } else if (currentCelebration === culturalCelebrations.length - 1) {
      setShowCompletion(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
  };

  const handlePrevious = () => {
    if (currentCelebration > 0) {
      setCurrentCelebration(prev => prev - 1);
      setShowTraditions(false);
      setShowLearning(false);
      setSelectedTraditions([]);
      setResponse('');
    }
  };

  const handleBackToMain = () => {
    navigate('/buddy-island');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-yellow-300 to-amber-400">
      {showConfetti && <Confetti />}
      <Header />
      <NavBar />

      <main className="flex-1 relative px-4 py-8">
        <button
          onClick={handleBackToMain}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-amber-700 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-amber-500 shadow-md"
        >
          ← Back to Buddy Island
        </button>

        {/* Progress Bar */}
        <div className="max-w-4xl mx-auto mb-8">
          <div className="flex items-center justify-between mb-2 bg-white/20 p-4 rounded-xl">
            {culturalCelebrations.map((_, index) => (
              <div
                key={index}
                className={`w-16 h-4 rounded-full transition-all duration-300 ${
                  completedCelebrations.has(index)
                    ? 'bg-green-400 animate-pulse'
                    : index === currentCelebration
                    ? 'bg-white'
                    : 'bg-white/30'
                }`}
              />
            ))}
          </div>
          <p className="text-center text-white font-bold text-lg">
            Celebration {currentCelebration + 1} of {culturalCelebrations.length}
          </p>
        </div>

        {!showCompletion ? (
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-8">
              <h1 className="text-5xl font-bold text-white mb-4 animate-bounce-slow">
                Community Circle
              </h1>
              <p className="text-2xl text-white">
                Celebrating Different Cultures and Backgrounds
              </p>
            </div>

            <div className="bg-white/90 backdrop-blur-md rounded-3xl border-4 border-white p-8 shadow-2xl">
              <div className="bg-yellow-100 rounded-xl p-6 mb-6 transform transition-all duration-300">
                <h2 className="text-3xl font-bold text-amber-600 mb-4">
                  {culturalCelebrations[currentCelebration].title}
                </h2>
                <p className="text-xl text-amber-700 mb-6">
                  {culturalCelebrations[currentCelebration].description}
                </p>

                {!showTraditions && (
                  <button
                    onClick={() => setShowTraditions(true)}
                    className="bg-amber-500 text-white px-8 py-4 rounded-full hover:bg-amber-600 transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg"
                  >
                    Discover Traditions 🌟
                  </button>
                )}

                {showTraditions && (
                  <div className="mt-6 animate-fade-in">
                    <div className="bg-amber-200 rounded-xl p-6 mb-6 border-2 border-amber-300">
                      <h3 className="text-2xl font-bold text-amber-600 mb-4">
                        Traditional Celebrations:
                      </h3>
                      <ul className="space-y-3">
                        {culturalCelebrations[currentCelebration].traditions.map((tradition, index) => (
                          <li
                            key={index}
                            className="text-lg text-amber-800 flex items-center space-x-2"
                          >
                            <span className="text-2xl">•</span>
                            <span>{tradition}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {!showLearning && (
                      <button
                        onClick={() => setShowLearning(true)}
                        className="bg-amber-500 text-white px-8 py-4 rounded-full hover:bg-amber-600 transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg"
                      >
                        What Can We Learn? 💭
                      </button>
                    )}
                  </div>
                )}

                {showLearning && (
                  <div className="mt-6 animate-fade-in">
                    <div className="bg-amber-200 rounded-xl p-6 mb-6 border-2 border-amber-300">
                      <p className="text-xl text-amber-800 mb-4">
                        {culturalCelebrations[currentCelebration].learning}
                      </p>
                      <h3 className="text-2xl font-bold text-amber-600 mt-6 mb-4">
                        Reflection Question:
                      </h3>
                      <p className="text-xl text-amber-800 mb-4">
                        {culturalCelebrations[currentCelebration].reflection}
                      </p>
                      <textarea
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                        placeholder="Write your thoughts here..."
                        className="w-full p-4 rounded-xl border-2 border-amber-300 focus:border-amber-500 focus:ring-2 focus:ring-amber-500 text-lg text-amber-800"
                        rows="4"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between mt-6">
                <button
                  onClick={handlePrevious}
                  disabled={currentCelebration === 0}
                  className={`px-8 py-4 rounded-full transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg ${
                    currentCelebration === 0
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-amber-500 hover:bg-amber-600'
                  } text-white`}
                >
                  ← Previous
                </button>
                <button
                  onClick={handleNext}
                  disabled={!showLearning || !response.trim()}
                  className={`px-8 py-4 rounded-full transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg ${
                    !showLearning || !response.trim()
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-amber-500 hover:bg-amber-600'
                  } text-white`}
                >
                  Next →
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-2xl mx-auto text-center bg-white/90 rounded-3xl p-12 animate-bounce-slow">
            <h2 className="text-5xl font-bold text-amber-600 mb-6">🌟 Amazing Work! 🌟</h2>
            <p className="text-2xl text-amber-700 mb-8">
              You've learned about different cultural celebrations and what makes them special!
            </p>
            <button
              onClick={handleBackToMain}
              className="bg-amber-500 text-white px-8 py-4 rounded-full hover:bg-amber-600 transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg"
            >
              Back to Buddy Island →
            </button>
          </div>
        )}
      </main>
      <Footer />

      <style jsx>{`
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        @keyframes fade-in {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-bounce-slow {
          animation: bounce-slow 3s infinite;
        }

        .animate-fade-in {
          animation: fade-in 0.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default BuddyActivity2;