import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import GuestModal from '../components/GuestModal';
import Footer from '../components/Footer';

const LocationMarker = ({ 
  icon, 
  label, 
  iconPosition = 'left', 
  lockPosition = 'right', 
  iconSize = 'w-[100px] h-[100px]', 
  moduleId,
  onClick 
}) => {
  const navigate = useNavigate();
  const { unlockedModules } = useAuth();
  const isLocked = moduleId ? !unlockedModules[moduleId] : true;

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default behavior
    if (isLocked) {
      return; // Do nothing if locked
    }
    if (onClick) {
      onClick();
    } else {
      // Default navigation if onClick not provided
      if (moduleId === 'emotions-falls') {
        navigate('/emotions-falls');
      } else if (moduleId === 'lava-island') {
        navigate('/lava-island');
      }
    }
  };

  return (
    <button
      className="group transition-transform hover:scale-[1.02] duration-200"
      onClick={handleClick}
    >
      <div className={`flex items-center gap-1 ${iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse'}`}>
        <div className="relative z-10">
          <img
            src={`/images/onlineclasses/${icon}.gif`}
            alt={`${label} Icon`}
            className={`${iconSize} ${iconPosition === 'left' ? 'translate-x-3' : '-translate-x-3'} pointer-events-none`}
          />
        </div>
        <div className="bg-yellow-300 px-6 py-2 rounded-full text-base flex items-center relative z-20 group-hover:bg-[#87CEEB] transition-colors duration-200 whitespace-nowrap">
          {isLocked && (
            <div className={`absolute ${lockPosition === 'left' ? '-left-5' : '-right-5'} z-30`}>
              <img
                src="/images/onlineclasses/lock.png"
                alt="Lock Icon"
                className="w-[30px] h-[30px] pointer-events-none"
              />
            </div>
          )}
          {label}
        </div>
      </div>
    </button>
  );
};

const CurriculumUnit = ({ image, title, unitNumber, goal, activities }) => (
  <div className="flex flex-col md:flex-row gap-8 py-8 border-b border-gray-200 last:border-0">
    <div className="w-full md:w-[220px] flex-shrink-0">
      <img 
        src={image}
        alt={title}
        className="w-full object-contain rounded-2xl shadow-lg"
      />
    </div>
    <div className="flex-1">
      <h3 className="text-teal-600 text-3xl font-bold mb-2">{title}</h3>
      <p className="text-gray-600 text-lg mb-4">Unit {unitNumber}</p>
      <div className="bg-teal-50 rounded-xl p-4 mb-4">
        <p className="font-bold text-teal-700 text-lg mb-1">Goal:</p>
        <p className="text-gray-700 leading-relaxed">{goal}</p>
      </div>
      <div className="bg-orange-50 rounded-xl p-4">
        <p className="font-bold text-teal-700 text-lg mb-2">Activities:</p>
        <ul className="space-y-2 pl-5 list-disc">
          {activities.map((activity, index) => (
            <li key={index} className="text-gray-700 leading-relaxed">
              {activity}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const WellnessMetropolis = () => {
  const [isGuestOpen, setIsGuestOpen] = useState(false);
  const navigate = useNavigate();
  
  const curriculumUnits = [
    {
      title: "Thinking About Your Emotions",
      unitNumber: 1,
      goal: "Students will understand what SEL is and begin to recognize and understand their own emotions.",
      activities: [
        "Module 1: Introduction to SEL: What it is and why it's important",
        "Module 2: Emotion Wheel: Identifying and discussing different emotions",
        "Module 3: Deep breathing exercises, Deep breathing and simple meditation",
        "Module 4: 'All About Me' projects: Students create posters or presentations about their likes, dislikes, and feelings"
      ]
    },
    {
      title: "Self-Management",
      unitNumber: 2,
      goal: "Students will learn strategies for managing their emotions and behaviors.",
      activities: [
        "Module 1: Time Management: Simple planning and organizational skills",
        "Module 2: Time-saving scenarios: Practicing real-world situations",
        "Module 3: Mindfulness routines: Continuing with daily practices",
        "Module 4: Creating a Calm-Down Kit: Tools and strategies for self-regulation"
      ]
    },
    {
      title: "Social Awareness",
      unitNumber: 3,
      goal: "Students will develop empathy and understand the perspectives of others.",
      activities: [
        "Module 1: Empathy exercises: Reading stories and discussing characters' feelings",
        "Module 2: Community Circle: Sharing and listening to others' perspectives",
        "Module 3: Stories of Good Deeds: Our kindness and friend journal",
        "Module 4: Celebrating different cultures and backgrounds"
      ]
    },
    {
      title: "Relationship Skills",
      unitNumber: 4,
      goal: "Students will learn how to build and maintain healthy relationships.",
      activities: [
        "Module 1: Team-building games: Cooperation and communication activities",
        "Module 2: 'I Am' statements: Positive affirmations and sharing compliments",
        "Module 3: Conflict resolution: Techniques for resolving disagreements",
        "Module 4: Friendship skills: Lessons on making and keeping friends"
      ]
    }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />
      <main className="flex-1 bg-[#b7f7fc]">
        <div className="relative mx-auto mt-12">
          <div className="max-w-5xl mx-auto text-center pt-16 px-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 mb-12">
              <h1 className="text-4xl font-bold text-teal-600 mb-8 tracking-wide">
                JOIN OUR SEL ONLINE WORLD CLASSES
              </h1>
              <p className="text-gray-600 mb-6 max-w-3xl mx-auto leading-relaxed">
                Welcome to our innovative interactive online class, designed to empower students through a unique,
                student-paced learning experience centered on Social Emotional Learning (SEL). This program offers a
                dynamic and engaging educational journey where students can explore SEL topics at their own pace while
                being guided and mentored through an interactive animated setting.{' '}
                <button 
                  onClick={() => setIsGuestOpen(true)}
                  className="inline-flex items-center gap-2 px-6 py-2 bg-teal-400 hover:bg-teal-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200 ml-2"
                >
                  <span>👋</span> Member Login
                </button>
              </p>
            </div>
          </div>

          <div className="flex justify-center">
            <img 
              src="/images/onlineclasses/wellnessmetropolis.gif" 
              alt="Wellness Metropolis"
              className="w-[700px] sm:w-[750px] md:w-[800px] lg:w-[850px] xl:w-[600px]"
            />
          </div>

          <div className="relative max-w-3xl mx-auto">
            <div className="relative">
              <img 
                src="/images/onlineclasses/classesbg.gif" 
                alt="Landscape"
                className="w-full object-contain"
              />

              <div className="absolute top-[6%] left-[-14%]">
                <LocationMarker 
                  icon="water" 
                  label="Emotions Falls" 
                  iconPosition="right" 
                  lockPosition="left"
                  moduleId="emotions-falls"
                  onClick={() => navigate('/emotions-falls')}
                />
              </div>
              <div className="absolute top-[36%] left-[-17%]">
                <LocationMarker 
                  icon="leaf" 
                  label="Together Land" 
                  iconPosition="right" 
                  lockPosition="left"
                  moduleId="together-land"
                  iconSize="w-[130px] h-[130px]"
                  onClick={() => navigate('/together-land')}
                />
              </div>

              <div className="absolute top-[5%] right-[-13%]">
                <LocationMarker 
                  icon="flame" 
                  label="Lava Island" 
                  iconPosition="left"
                  lockPosition="right"
                  moduleId="lava-island"
                  iconSize="w-[150px] h-[150px]"
                />
              </div>
              <div className="absolute bottom-[35%] right-[-7%]">
                <LocationMarker 
                  icon="sun" 
                  label="Buddy Island" 
                  iconPosition="left"
                  lockPosition="right"
                  moduleId="buddy-island"
                  onClick={() => navigate('/buddy-island')} // Add this line
                />
              </div>

              <div className="absolute bottom-0 w-full -mb-[500px]">
                <img 
                  src="/images/onlineclasses/clouds.gif" 
                  alt="Cloud Base"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-white w-full">
          <div className="max-w-[1000px] mx-auto px-12 pt-24 pb-16">
            <div className="bg-white rounded-3xl shadow-lg p-8">
              {curriculumUnits.map((unit, index) => (
                <CurriculumUnit
                  key={index}
                  image={`/images/onlineclasses/unit${unit.unitNumber}.png`}
                  title={unit.title}
                  unitNumber={unit.unitNumber}
                  goal={unit.goal}
                  activities={unit.activities}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />

      <GuestModal 
        isOpen={isGuestOpen} 
        onClose={() => setIsGuestOpen(false)} 
      />
    </div>
  );
};

export default WellnessMetropolis;