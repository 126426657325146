import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

// Internal Video Modal Component
const VideoModal = ({ isOpen, onClose, videoId }) => {
  // const [canPlayVideo, setCanPlayVideo] = useState(false);
  // const audioRef = useRef(null);
  
  // useEffect(() => {
  //   if (isOpen) {
  //     // Create new audio instance for the intro
  //     audioRef.current = new Audio('/audio/buddyisland/bigbigemotionsactivity.m4a');
      
  //     // Play the intro audio
  //     const playAudio = async () => {
  //       try {
  //         await audioRef.current.play();
  //       } catch (error) {
  //         console.error("Intro audio playback failed:", error);
  //         setCanPlayVideo(true);
  //       }
  //     };
      
  //     playAudio();
      
  //     // When intro audio ends, allow video playback
  //     audioRef.current.onended = () => {
  //       setCanPlayVideo(true);
  //     };
  //   } else {
  //     // Cleanup when modal closes
  //     if (audioRef.current) {
  //       audioRef.current.pause();
  //       audioRef.current.currentTime = 0;
  //     }
  //     setCanPlayVideo(false);
  //   }
    
  //   return () => {
  //     if (audioRef.current) {
  //       audioRef.current.pause();
  //       audioRef.current.currentTime = 0;
  //     }
  //   };
  // }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/75 flex justify-center items-center z-50">
      <div className="relative w-[90%] max-w-4xl aspect-video bg-white rounded-lg overflow-hidden">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 z-[1002] bg-white border-none rounded-full w-8 h-8 cursor-pointer flex items-center justify-center text-xl font-bold shadow-md hover:bg-gray-100"
        >
          ×
        </button>
        {/* Commented out conditional rendering for future use */}
        {/* {!canPlayVideo ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-100 text-gray-600 text-lg p-5 text-center">
            <p>Please wait while the introduction plays...</p>
          </div>
        ) : ( */}
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute inset-0 w-full h-full border-none"
          />
        {/* )} */}
      </div>
    </div>
  );
};

const BuddyIsland = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState('');
  const backgroundAudioRef = useRef(null);

  useEffect(() => {
    // Create an audio instance
    // backgroundAudioRef.current = new Audio('/audio/buddyisland/background-voice.m4a');

    // Play the audio
    // backgroundAudioRef.current.play().catch((error) => {
    //   console.error("Audio playback failed:", error);
    // });

    return () => {
      // if (backgroundAudioRef.current) {
      //   backgroundAudioRef.current.pause();
      //   backgroundAudioRef.current.currentTime = 0;
      // }
    };
  }, []);

  const modules = [
    {
      title: 'READ ALOUD:',
      description: 'What Makes a Good Friend?',
      due: 'December Activity 1',
      videoId: 'XTYfji4YiS0'
    },
    {
      title: 'LENDING EAR:',
      description: "Sharing and Listening to Others' Experiences",
      due: 'January Activity 2',
      path: '/buddy-island/buddy-activity1'
    },
    {
      title: 'COMMUNITY CIRCLE:',
      description: 'Celebrating Different Cultures and Backgrounds',
      due: 'January Activity 3',
      path: '/buddy-island/buddy-activity2'
    },
    {
      title: 'KINDNESS CHAIN:',
      description: 'Building Connections Through Acts of Kindness',
      due: 'January Activity 4',
      path: '/buddy-island/buddy-activity3'
    },
  ];

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleVideoClick = (videoId) => {
    // if (backgroundAudioRef.current) {
    //   backgroundAudioRef.current.pause();
    // }
    setCurrentVideoId(videoId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentVideoId('');
    // if (backgroundAudioRef.current) {
    //   backgroundAudioRef.current.play().catch((error) => {
    //     console.error("Audio playback failed:", error);
    //   });
    // }
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-b from-yellow-400 to-amber-500">
      <Header />
      <NavBar />
      <main
        className="flex-1 relative overflow-hidden w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/buddyisland/background.png')" }}
      >
        {/* Sun (Right) and Mountains (Left) Images */}
        <div className="absolute top-20 left-8 z-10 w-72 md:w-80 lg:w-96 animate-float-slow">
          <img
            src="/images/buddymountain/bg.png"
            alt="Buddy Island"
            className="w-full h-auto"
          />
        </div>
        <div className="absolute top-28 right-8 z-20 w-64 h-64 md:w-72 md:h-72 animate-bounce-slow">
          <img
            src="/images/onlineclasses/sun.gif"
            alt="Sun Character"
            className="w-full h-full"
          />
        </div>

        {/* Content Overlay */}
        <div className="relative pt-48 pb-16 flex flex-col items-center px-4">
          <div className="bg-white/10 backdrop-blur-md p-8 rounded-3xl max-w-4xl w-full text-center shadow-xl border border-white/20">
            <h1 className="text-5xl md:text-6xl font-bold text-white tracking-wider mb-6 animate-fade-in">
              WELCOME TO<br />BUDDY ISLAND
            </h1>
            <h2 className="text-2xl md:text-4xl text-white font-bold mb-4">
              RELATIONSHIP SKILLS
            </h2>
            <p className="text-lg md:text-2xl text-yellow-100 mb-8">Module 4 | February</p>

            <div className="space-y-6 bg-black/20 p-6 rounded-2xl">
              <p className="text-yellow-50 text-lg leading-relaxed">
                Goal: Students will develop empathy and understand the perspectives of others.
              </p>
              <p className="text-yellow-50 text-lg leading-relaxed">
                At the end of the lesson, students will engage in activities that promote understanding of diverse perspectives and empathy. 
                This unit includes group discussions, storytelling, and interactive exercises to build connections and compassion. 
                These activities will help students foster a sense of community and support their social development.
              </p>
            </div>
          </div>

          {/* Modules Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 mt-16 max-w-7xl mx-auto">
            {modules.map((module, index) => (
              <div 
                key={index} 
                className="bg-white/10 backdrop-blur-sm p-8 rounded-3xl flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300 border border-white/20"
              >
                <h3 className="text-white text-lg md:text-xl font-semibold uppercase mb-3">
                  {module.title}
                </h3>
                <p className="text-yellow-100 text-md md:text-lg mb-2">
                  {module.description}
                </p>
                <p className="text-yellow-200 text-md md:text-lg mb-4">
                  {module.due}
                </p>
                <button 
                  onClick={() => module.videoId ? handleVideoClick(module.videoId) : handleNavigate(module.path)}
                  className="bg-gradient-to-r from-yellow-400 to-amber-400 text-white px-10 py-3 rounded-full text-md md:text-lg hover:from-yellow-500 hover:to-amber-500 transition-all duration-300 transform hover:-translate-y-1 shadow-lg hover:shadow-xl font-bold"
                >
                  Begin Adventure
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />

      <VideoModal 
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        videoId={currentVideoId}
      />

      <style jsx>{`
        @keyframes float-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-20px); }
        }
        
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-15px); }
        }

        @keyframes fade-in {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-float-slow {
          animation: float-slow 6s ease-in-out infinite;
        }

        .animate-bounce-slow {
          animation: bounce-slow 4s ease-in-out infinite;
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default BuddyIsland;