// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

// NavBar Component
const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Monthly ❤️ Activities', path: '/community-challenges' },
    // { name: 'Youth SEL Empower Lounge', path: '/empower-lounge' },
    { name: 'Online Classes', path: '/online-classes' },
    { name: 'SEL Bookshelf 📚', path: '/classroom-tools' },
    { name: 'Contact Us', path: '/contact' },
    { name: 'Blog', path: '/blog' }
  ];

  return (
    <nav className="bg-teal-400 text-white shadow-md">
      <div className="md:hidden flex justify-between items-center px-4 py-3">
        <span className="font-medium text-lg">Menu</span>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 hover:bg-teal-300 rounded-full transition-colors"
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block`}>
        <ul className="flex flex-col md:flex-row md:flex-wrap md:justify-center items-start md:items-center py-2">
          {navItems.map((item, index) => (
            <li key={item.name} className="w-full md:w-auto flex items-center">
              <Link 
                to={item.path} 
                className="block px-4 py-3 md:px-6 md:py-4 text-sm md:text-base hover:bg-teal-300 transition-colors whitespace-nowrap font-medium rounded-lg md:rounded-none"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item.name}
              </Link>
              {index < navItems.length - 1 && (
                <div className="hidden md:block h-8 w-0.5 bg-white/20 rounded-full mx-1" />
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;